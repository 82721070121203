import React from "react";
//stitches
import { styled } from "../../sitches.config";

const Container = styled("div", {
  width: "100%",
  paddingX: "15px",
  maxWidth: "1200px",
  marginX: "auto"
});

export function ContainerFluid({
  width,
  paddingX,
  maxWidth,
  marginX,
  css,
  children
}) {
  return (
    <Container
      componentname="container-fluid"
      css={{ ...css, width, paddingX, maxWidth, marginX }}
    >
      {children}
    </Container>
  );
}
