import React from "react";
import { styles } from "./HomeHeader.styles";
import { MenuNav } from "@obby/components";

export default function HomeHeader({
  title,
  description,
  className,
  textSection,
  imageSection,
  backgroundColor = "hummingBird",
  color = "ebonyClay"
}) {
  return (
    <div
      className={styles.root({
        className,
        css: { backgroundColor: `$${backgroundColor}`, color: `$${color}` }
      })}
    >
      <MenuNav.Placeholder />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-smd-6">
            <div className={styles.searchSection()}>
              <h1 className={styles.title()}>{title}</h1>
              {description && (
                <p className={styles.description()}>{description}</p>
              )}
              {textSection && textSection()}
            </div>
          </div>

          <div className="col-6 d-none d-smd-block">
            {imageSection && imageSection()}
          </div>
        </div>
      </div>
    </div>
  );
}
