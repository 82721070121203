import { css, withBem } from "@obby/components";

export const styles = withBem("home-header", {
  root: css({
    paddingBottom: "$4"
  }),
  wrap: css({
    display: "flex",
    flexDirection: "column-reverse",
    "@sm": {
      flexDirection: "row"
    }
  }),
  searchSectionWrap: css({
    display: "flex",
    alignItems: "center",
    width: "100%",
    zIndex: "1",

    "@md": {
      width: "50%"
    }
  }),
  searchSection: css({
    padding: "15px 0 0",

    "@lg": {
      padding: "0 40px 0 0"
    }
  }),
  title: css({
    fontFamily: "$foundersGrotesk",
    fontWeight: "bold",
    fontSize: "46px",
    lineHeight: "1",
    textTransform: "uppercase",
    margin: "0 0 20px",

    "@md": {
      fontSize: "62px"
    }
  }),
  description: css({
    fontFamily: "$foundersGrotesk",
    fontWeight: "400",
    fontSize: "$lParagraph",
    lineHeight: "$lParagraphLh",
    color: "$ebonyClay"
  }),
  imageSection: css({
    width: "100%",
    display: "none",
    overflow: "hidden",
    maxHeight: "300px",
    borderRadius: "10px",
    marginTop: "$40",
    "@sm": {
      display: "block"
    },

    "@md": {
      display: "flex",
      width: "50%",
      justifyContent: "center",
      alignItems: "flex-start",
      overflow: "auto",
      maxHeight: "none"
    }
  })
});
