import { styled, css } from "../../sitches.config";

export const Box = styled("div", {
  variants: {
    variant: {
      reviewsBanner: {
        padding: "1rem 0.2rem",
        borderRadius: "$standard",

        "@lg": {
          padding: "$8 $16",
          borderRadius: "0px"
        }
      },

      wrapper: {
        "@lg": {
          display: "flex",
          justifyContent: "space-between"
        }
      },

      brand: {
        "@lg": {
          display: "flex",
          alignItems: "center"
        }
      },

      review: {
        svg: {
          marginInline: "0.25rem"
        }
      },

      reviews: {
        textAlign: "center",
        fontFamily: "$foundersGroteskXCondensed",
        fontSize: "$24",
        "@lg": {
          alignSelf: "center",
          fontSize: "$32",
          marginBottom: "4px"
        }
      },

      title: {
        fontFamily: "$foundersGroteskXCondensed",
        fontSize: "$22",
        letterSpacing: "0.35px",
        lineHeight: "1",
        marginBottom: "1.2rem",
        color: "inherit",
        textTransform: "uppercase",
        textAlign: "center",

        "@sm": {
          marginBottom: "0"
        },
        "@lg": {
          marginTop: "$12",
          fontSize: "$32",
          paddingBottom: "$16"
        }
      }
    }
  }
});

export const ListWrapper = styled("ul", {
  variants: {
    variant: {
      icons: {
        paddingLeft: "0rem",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        listStyleType: "none",
        gap: "$12",
        marginBottom: "0",

        "@sm": {
          paddingLeft: "$32"
        }
      }
    }
  }
});

export const ListITem = styled("li", {
  variants: {
    variant: {
      icon: {
        width: "5.625rem",
        display: "flex",
        alignItems: "center",

        "&:nth-child(3), &:nth-child(4)": {
          width: "8.5rem"
        }
      }
    }
  }
});

export const Img = styled("img", {
  maxWidth: "100%",
  maxHeight: "100%",
  objectFit: "cover",
  filter: "grayscale(1) brightness(0.25) invert(1)"
});

export const Svg = css({
  paddingInline: "0.125rem",
  marginBottom: "0.15rem",
  marginInline: "0.25rem",
  polygon: {
    fill: "$sun"
  }
});
