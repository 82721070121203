import React from "react";
import Head from "next/head";
import { facebook } from "~/settings";

export function HomePageMetadata() {
  const meta = {
    title: "Discover London's favourite courses, classes and workshops - Obby",
    desc:
      "Learn something new today. Discover 1000s of classes, courses and workshops across the UK.",
    image: "https://obby.co.uk/static/images/metadata_card.jpg"
  };

  const ldData = {
    "@context": "http://schema.org/",
    "@type": "Website",
    name: "Obby",
    description:
      "Learn something new today. Discover 1000s of classes, courses and workshops across the UK.",
    image: "https://obby.co.uk/static/images/metadata_card.jpg",
    url: "https://obby.co.uk",
    potentialAction: {
      "@type": "SearchAction",
      target: "https://obby.co.uk/classes?q=search_term_string",
      "query-input": "required name=search_term_string"
    },
    sourceOrganization: {
      "@type": "Organization",
      name: "Obby",
      description:
        "Discover London's favourite courses, classes and workshops.",
      image: "https://obby.co.uk/static/images/metadata_card.jpg",
      url: "https://obby.co.uk",
      address: {
        "@type": "PostalAddress",
        addressLocality: "London",
        postalCode: "W1U 6BP",
        streetAddress: "1 Bickenhall Mansions, Bickenhall Street"
      },
      logo: {
        "@type": "ImageObject",
        url: "https://obby.co.uk/static/images/metadata_card.jpg"
      }
    }
  };
  return (
    <Head>
      <title>{meta.title}</title>
      <meta name="description" content={meta.desc} />
      <meta property="fb:app_id" content={facebook.appId} />
      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.desc} />
      <meta property="og:url" content="https://obby.co.uk" />
      <meta property="og:image" content={meta.image} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:site_name" content="Obby" />
      <meta property="twitter:title" content={meta.title} />
      <meta property="twitter:description" content={meta.desc} />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:image" content={meta.image} />
      <meta property="twitter:image:alt" content={meta.title} />
      <link rel="canonical" href="https://obby.co.uk" />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: `${JSON.stringify(ldData)}` }}
      />
    </Head>
  );
}
