import { styled } from "../../sitches.config";

export const Box = styled("div", {
  variants: {
    variant: {
      trustedByBanner: {
        padding: "$30 $14",
        borderRadius: "$standard",

        "@lg": {
          padding: "$36"
        }
      },

      title: {
        fontFamily: "$foundersGroteskCondensed",
        fontWeight: "bold",
        fontSize: "$22",
        lineHeight: "1",
        color: "inherit",
        textTransform: "uppercase",
        textAlign: "center",

        "@lg": {
          fontSize: "$36",
          paddingBottom: "$16"
        }
      }
    }
  }
});

export const ListWrapper = styled("ul", {
  variants: {
    variant: {
      icons: {
        alignItems: "baseline",
        paddingLeft: "$32",
        marginTop: "$16",
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: "$12",
        listStyleType: "none",

        "@sm": {
          paddingLeft: "$32",
          gridTemplateColumns: "repeat(5, 1fr)"
        },
        "@md": {
          gridTemplateColumns: "repeat(9, 1fr)",
          height: "5.5rem"
        }
      }
    }
  }
});

export const ListItem = styled("li", {
  variants: {
    variant: {
      icon: {
        width: "4.375rem",
        height: "3.125rem",
        display: "flex",
        alignItems: "end",
        "&:nth-last-child(1)": {
          width: "3.438rem",
          height: "3.125rem",
          marginLeft: "$10"
        },
        "&:nth-child(2n)": {
          alignSelf: "flex-end"
        },

        "@sm": {
          "&:nth-child(6), &:nth-child(7), &:nth-child(8), &:nth-child(9)": {
            justifySelf: "flex-end"
          }
        },

        "@md": {
          width: "initial",

          "&:nth-last-child(1)": {
            marginTop: "-$10",
            marginLeft: "$10"
          },
          "&:nth-child(6), &:nth-child(7), &:nth-child(8), &:nth-child(9)": {
            justifySelf: "initial"
          }
        }
      }
    }
  }
});

export const Img = styled("img", {
  maxWidth: "100%",
  maxHeight: "100%",
  objectFit: "cover",
  filter: "grayscale(1) brightness(0.25) invert(1)"
});
