import React from "react";
import axios from "~/config/axios";
import { HomePageContainer } from "./HomePage.container";
import { HomePageView } from "./HomePage.view";

export function HomePage(props) {
  return (
    <HomePageContainer.Provider initialState={props}>
      <HomePageView />
    </HomePageContainer.Provider>
  );
}

export async function getServerSideProps({ res }) {
  const response = await axios.get("pages/home");
  const {
    tags,
    reviews,
    upcomingClasses,
    products,
    courses,
    featuredClasses
  } = response.data;

  // 1 min cache
  res.setHeader(
    "Cache-Control",
    "public, s-maxage=60, stale-while-revalidate=119"
  );
  return {
    props: {
      tags,
      reviews,
      upcomingClasses,
      products,
      courses,
      featuredClasses
    }
  };
}
