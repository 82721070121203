import React from "react";
import { bem } from "../../configs/bem";
import { CloudinaryImage } from "../../components/CloudinaryImage";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import { styles } from "./WideBanner.styles";

const cn = bem("wide-banner");
export function WideBanner(props) {
  const {
    title,
    description,
    image,
    buttonText,
    onButtonClick,
    background = "azalea",
    foreground = "ebonyClay",
    buttonColor = "gallery",
    sticker
  } = props;
  return (
    <div
      className={`${styles.wideBanner({
        css: {
          color: `$${foreground}`,
          backgroundColor: `$${background}`
        }
      })}`}
    >
      <div className="row">
        <div className="col-12 col-smd-5 col-md-5">
          <div className={`${styles.colWrapper}`}>
            <CloudinaryImage
              width={600}
              height={600}
              className={`${styles.image}`}
              src={image}
            />
            {sticker && (
              <CloudinaryImage
                width={284}
                height={105}
                className={`${styles.sticker}`}
                src={sticker}
              />
            )}
          </div>
        </div>
        <div className="col-12 col-smd-7 col-md-6 offset-md-1">
          <div className={`${styles.colWrapper}`}>
            <div className={`${styles.title} mt-4 mb-2 mt-smd-0`}>{title}</div>
            <div className={`${styles.description}`}>{description}</div>
            <div className={`${styles.actions} mt-4`}>
              <PrimaryButton
                className={`${styles.action}`}
                text={buttonText}
                color={buttonColor}
                width="auto"
                onClick={onButtonClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
