import React from "react";

const Icon = ({ width, height, icon, className }) => (
  <svg
    width={width}
    height={height}
    viewBox={icon.viewBox}
    className={className || ""}
  >
    {icon.body()}
  </svg>
);

export default Icon;
