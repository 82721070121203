import React from "react";
import { bem } from "../../configs/bem";
import Icon from "../../components/Icons/Icon";
import { Star } from "../../components/Icons/Icons";
import { Box, Img, ListWrapper, ListITem, Svg } from "./ReviewsBanner.layout";
import ContainerFluid from "../../components/ContainerFluid";

const cn = bem("reviews-banner");
export function ReviewsBanner({ background, foreground }) {
  const brands = [
    {
      name: "guardian",
      imageURL:
        "https://res.cloudinary.com/obby/image/upload/v1639483691/Page%20Images/theguardian.png"
    },
    ,
    {
      name: "timeout",
      imageURL:
        "https://res.cloudinary.com/obby/image/upload/v1639472733/Page%20Images/TimeOut.png"
    },
    {
      name: "thetimes",
      imageURL:
        "https://res.cloudinary.com/obby/image/upload/v1639472733/Page%20Images/TheTimesLogo.png"
    },
    ,
    {
      name: "evening",
      imageURL:
        "https://res.cloudinary.com/obby/image/upload/v1639483673/Page%20Images/eveningstalogo.png"
    },
    {
      name: "forbes",
      imageURL:
        "https://res.cloudinary.com/obby/image/upload/v1639484031/Page%20Images/forbes-logo.png"
    }
  ];
  return (
    <Box
      className={cn()}
      variant="reviewsBanner"
      css={{ backgroundColor: `$${background}`, color: `$${foreground}` }}
    >
      <ContainerFluid>
        <Box className={cn("wrapper")} variant="wrapper">
          <Box className={cn("brand")} variant="brand">
            <Box className={cn("title")} variant="title">
              AS SEEN IN
            </Box>
            <ListWrapper className={cn("icons")} variant="icons">
              {brands.map(brand => (
                <ListITem
                  className={cn("icon")}
                  key={brand.name}
                  variant="icon"
                >
                  <Img
                    className={cn("icon__img")}
                    src={brand.imageURL}
                    alt={`image-brand-${brand.name}`}
                  />
                </ListITem>
              ))}
            </ListWrapper>
          </Box>

          <Box className={cn("reviews")} variant="reviews">
            5
            <Icon
              className={`m-class-card__rating-star ${Svg()}`}
              icon={Star}
              height={11}
            />
            (10,500+reviews)
          </Box>
        </Box>
      </ContainerFluid>
    </Box>
  );
}
