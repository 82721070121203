import React from "react";
import { AnimatedImageList } from "../components/AnimatedImageList";
import HomeHeader from "../components/HomeHeader";
import { Icons, AsyncSelectBox, DiscountBadge } from "@obby/components";
import { CloudinaryUrl__TransformedUrl } from "@obby/lib";

import { styles } from "./HeaderHomepageSales.styles";
export function HeaderHomepageSales({ discount, tags, onLoadTags, onSearch }) {
  return (
    <div className={styles.headerHomePageSales()}>
      <HomeHeader
        backgroundColor="ebonyClay"
        color="hummingBird"
        title={
          <>
            Black Friday! Take{" "}
            <strong className={styles.discount()}>{discount.value}%*</strong>{" "}
            off sitewide today
          </>
        }
        textSection={() => (
          <div>
            <p className={styles.callToAction()}>
              For 1 day only - what will you learn?
            </p>
            <AsyncSelectBox
              className={styles.searchInput()}
              icon={Icons.Search}
              onChange={onSearch}
              placeholder="What do you want to learn?"
              accent="ebonyClay"
              defaultOptions={tags}
              onLoad={onLoadTags}
              parseOption={todo => ({ value: todo._id, label: todo.name })}
              rounded
              noOptionsMessage="Try something else"
            />
            <p className={`header-homepage-sales__note ${styles.note()}`}>
              *{discount.value}% off all classes on the obby site, while stocks
              last!
            </p>
          </div>
        )}
        imageSection={() => (
          <div className={styles.imageSection()}>
            <AnimatedImageList
              images={[
                "https://res.cloudinary.com/obby/image/upload/v1667376613/Page%20Images/1._Copy_of_Obby_Leather-2.jpg",
                "https://res.cloudinary.com/obby/image/upload/v1667376676/Page%20Images/2._Copy_of_F34D536B-85B7-41AB-8CA7-6022324C8CDB.jpg",
                "https://res.cloudinary.com/obby/image/upload/v1667376678/Page%20Images/3._Copy_of_Obby_Bouqet-3.jpg",
                "https://res.cloudinary.com/obby/image/upload/v1667376678/Page%20Images/4._Copy_of_MP_Candle_17Sep-133.jpg",
                "https://res.cloudinary.com/obby/image/upload/v1667376678/Page%20Images/5._Copy_of_MP_Aug5th_Terrarium-1.jpg",
                "https://res.cloudinary.com/obby/image/upload/v1667376678/Page%20Images/6._Copy_of_Obby_Printmaking-9.jpg"
              ].map(image =>
                CloudinaryUrl__TransformedUrl(image, {
                  width: 640,
                  height: 454,
                  crop: "fill"
                })
              )}
              fadeInDuration={0}
              changeEvery={6000}
            />
            <div className={styles.discountBadgeWrapper()}>
              <DiscountBadge
                type={discount.type}
                value={discount.value}
                size={{ "@initial": "small", "@lg": "medium" }}
                rotate={25}
              />
            </div>
          </div>
        )}
      />
    </div>
  );
}
