import { css } from "@obby/components";

export const styles = {
  homePage: css({
    backgroundColor: "$gallery",

    "@sm": {
      padding: "0 0 $50"
    }
  }),
  section: css({
    padding: "$50 0 0",
    "&:nth-last-of-type(1)": {
      paddingBottom: "$50"
    },

    "@md": {
      padding: "$50 0 $50"
    },

    "&.d-sm-none": {
      ".row": {
        ".col-12:nth-of-type(1)": {
          display: "none"
        }
      }
    }
  }),

  title: css({
    fontFamily: "$foundersGroteskCondensed",
    fontSize: "$36",
    color: "$ebonyClay",
    lineHeight: "1",
    textTransform: "uppercase",

    "@md": {
      fontSize: "$40"
    }
  }),

  description: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$18",
    color: "$ebonyClay",

    "@md": {
      fontSize: "$20"
    }
  })
};
