import React from "react";
import { bem } from "../../configs/bem";
import { Box, Img, ListItem, ListWrapper } from "./TrustedByBanner.layout";

const cn = bem("trusted-by-banner");
export function TrustedByBanner({ background, foreground, title }) {
  const brands = [
    {
      name: "youtube",
      imageURL:
        "https://res.cloudinary.com/obby/image/upload/v1637085224/Page%20Images/youtube.png"
    },
    ,
    {
      name: "linklaters",
      imageURL:
        "https://res.cloudinary.com/obby/image/upload/v1637079331/Page%20Images/Link_227_C_1.png"
    },
    {
      name: "innocent",
      imageURL:
        "https://res.cloudinary.com/obby/image/upload/v1637079332/Page%20Images/innocent_1_1.png"
    },
    ,
    {
      name: "gousto",
      imageURL:
        "https://res.cloudinary.com/obby/image/upload/v1637085224/Page%20Images/gousto.png"
    },
    {
      name: "google",
      imageURL:
        "https://res.cloudinary.com/obby/image/upload/v1637079332/Page%20Images/368px-Google_2015_logo_1.png"
    },
    {
      name: "sainsbury",
      imageURL:
        "https://res.cloudinary.com/obby/image/upload/v1637085225/Page%20Images/sainsbury.png"
    },
    {
      name: "paypal",
      imageURL:
        "https://res.cloudinary.com/obby/image/upload/v1637085224/Page%20Images/paypal.png"
    },
    {
      name: "visa",
      imageURL:
        "https://res.cloudinary.com/obby/image/upload/v1637079332/Page%20Images/Visa-Logo_1.png"
    },
    {
      name: "warner-bros",
      imageURL:
        "https://res.cloudinary.com/obby/image/upload/v1637079331/Page%20Images/58483b6d84e35b574e745381_1.png"
    }
  ];
  return (
    <Box
      className={cn({ background, foreground })}
      css={{ color: `$${foreground}`, backgroundColor: `$${background}` }}
      variant="trustedByBanner"
    >
      <Box className={cn("title")} variant="title">
        {title}
      </Box>
      <ListWrapper className={cn("icons")} variant="icons">
        {brands.map(brand => (
          <ListItem className={cn("icon")} key={brand.name} variant="icon">
            <Img
              className={cn("icon__img")}
              src={brand.imageURL}
              alt={`image-brand-${brand.name}`}
            />
          </ListItem>
        ))}
      </ListWrapper>
    </Box>
  );
}
