import React from "react";
import axios from "~/config/axios";
import { useState, useEffect } from "react";
import { createContainer } from "~/config/createContainer";
import AuthenticationState from "~/state/AuthenticationState";

function useHomePageContainer(state) {
  const {
    tags,
    reviews,
    upcomingClasses,
    products,
    courses,
    featuredClasses
  } = state;

  const { homepageCarousel } = state.blockInstances;

  function getCourse(courseId) {
    return courses.find(course => course._id === courseId);
  }

  function onAddClassToWishList(courseId) {
    AuthenticationState.events.addClassToWishList.next(courseId);
  }

  function onRemoveClassFromWishList(courseId) {
    AuthenticationState.events.removeClassFromWishList.next(courseId);
  }

  async function onLoadGeoTags(query) {
    const response = await axios.get("pages/home/geotags", {
      params: { q: query }
    });
    return response.data;
  }

  async function onLoadTags(query) {
    const response = await axios.get("pages/home/tags", {
      params: { q: query }
    });
    return response.data;
  }

  function onSearchGeoTags(tag) {
    window.location.href = `/learn/?q=${tag.name}&format=inPerson`;
  }

  function onSearchTags(tag) {
    console.log("we hahere onSearch Tags,", tag);
    if (tag.hasLandingPageEnabled === false) {
      window.location.href = `/learn/?keyword=${tag.name}`;
    } else {
      window.location.href = `/learn/${tag.slug}`;
    }
  }

  async function onJoinObbyToday() {
    window.location.href = "/join";
  }

  async function onClickGiftCards() {
    window.location.href = "/gift";
  }

  async function onClickAllClasses() {
    window.location.href = "/learn";
  }

  return {
    tags,
    reviews,
    upcomingClasses,
    products,
    featuredClasses,
    homepageCarousel,
    helpers: { getCourse },
    actions: {
      onAddClassToWishList,
      onRemoveClassFromWishList,
      onClickAllClasses,
      onClickGiftCards,
      onJoinObbyToday,
      onLoadGeoTags,
      onLoadTags,
      onSearchGeoTags,
      onSearchTags
    }
  };
}

export const HomePageContainer = createContainer(useHomePageContainer);
