import React from "react";
import { styles } from "./AnimatedImageList.styles";

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imagesToLoopThrough: props.images,
      atImageIndex: 0,
      atImage: props.images[0],
      isShowing: false,
      loopInterval: null
    };
  }

  componentDidMount() {
    this.setState({
      loopInterval: window.setInterval(
        this.setNextImageToShow.bind(this),
        this.props.changeEvery || 6000
      )
    });
  }

  componentWillUnmount() {
    window.clearInterval(this.state.loopInterval);
  }

  setNextImageToShow() {
    const { fadeInDuration = 3000 } = this.props;
    var nextIndex = this.getNextIndex();

    this.setState({
      atImageIndex: nextIndex,
      atImage: this.state.imagesToLoopThrough[nextIndex],
      isShowing: true
    });

    window.setTimeout(() => {
      this.setState({
        isShowing: false
      });
    }, fadeInDuration);
  }

  getNextIndex() {
    if (this.state.atImageIndex === this.state.imagesToLoopThrough.length - 1) {
      return 0;
    }
    return this.state.atImageIndex + 1;
  }

  render() {
    const { fadeInDuration = 3000 } = this.props;
    return (
      <div className={`animated-images-list ${styles.animatedImagesList()}`}>
        <img
          className={`
            animated-images-list__image
            ${styles.image({ isShowing: this.state.isShowing })}
          `}
          style={{
            animationDuration: fadeInDuration + "ms"
          }}
          src={this.state.atImage}
        />
      </div>
    );
  }
}
