import { css } from "../../sitches.config";

export const styles = {
  wideBanner: css({
    padding: "30px",
    borderRadius: "20px",
    "@lg": {
      padding: "55px"
    }
  }),
  colWrapper: css({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    position: "relative",
    "@smd": {
      justifyContent: "center"
    }
  }),
  image: css({
    display: "block",
    width: "100%"
  }),
  sticker: css({
    position: "absolute",
    width: "150px",
    right: "-25px",
    bottom: "0",
    "@md": {
      bottom: "-10px",
      right: "-50px"
    },
    "@lg": {
      width: "200px",
      right: "-75px"
    },
    transform: "rotate(-21.39deg)"
  }),
  title: css({
    fontFamily: "$foundersGroteskCondensed",
    fontWeight: "bold",
    fontSize: "40px",
    lineHeight: "1",
    color: "inherit",
    textTransform: "uppercase",
    "@smd": {
      textAlign: "right"
    },
    "@lg": {
      fontSize: "64px"
    }
  }),
  description: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "18px",
    lineHeight: "21px",
    color: "inherit",
    "@smd": {
      textAlign: "right"
    },
    "@lg": {
      fontSize: "20px"
    }
  }),
  actions: css({
    "@smd": {
      textAlign: "right"
    }
  }),
  action: css({
    minWidth: "250px"
  })
};
