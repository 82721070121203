import { css, animations } from "@obby/components";

export const styles = {
  animatedImagesList: css({
    width: "100%",
    borderRadius: "10px",
    overflow: "hidden"
  }),

  image: css({
    display: "block",
    width: "100%",

    variants: {
      isShowing: {
        true: {
          animationName: `${animations.fadeIn}`
        }
      }
    }
  })
};
