import React from "react";
// Components
import { HeaderHomepageSales } from "~/sections/HeaderHomepageSales";
import { HomePageMetadata } from "./HomePage.metadata";
import AuthenticationState from "~/state/AuthenticationState";
import {
  bem,
  Icons,
  InfoBanner,
  MotivationBanner,
  ResponsiveClassCards,
  ResponsiveProductsCards,
  ReviewsSlider,
  ResponsiveClassSection,
  Carousel,
  WideBannerWithImage
} from "@obby/components";

// Containers
import { HomePageContainer } from "./HomePage.container";
import { SearchHeader } from "@obby/components/src/headers/SearchHeader";
import { SquareCard } from "@obby/components/src/cards/SquareCard";
import { WideBanner } from "@obby/components/src/banners/WideBanner";
import { ContainerFluid } from "@obby/components/src/components/ContainerFluid/ContainerFluid";
import { TrustedByBanner } from "@obby/components/src/banners/TrustedByBanner";
import { ReviewsBanner } from "@obby/components/src/banners/ReviewsBanner";
import { styles } from "./HomePage.styles";

import {
  Course__CourseUrl,
  Course__IsPriceFrom,
  Course__Price,
  ImageUrl__ImageResizedUrl,
  ImageUrl__ResizedImageUrl,
  Product__ProductUrl
} from "@obby/lib";

const cn = bem("home-page");
export function HomePageView(props) {
  const {
    tags,
    reviews,
    upcomingClasses,
    products,
    helpers,
    featuredClasses,
    actions,
    homepageCarousel
  } = HomePageContainer.useContainer();

  //Static for test
  const geotags = [
    { name: "London", slug: "?q=London&format=inPerson" },
    { name: "Brighton", slug: "?q=Brighton&format=inPerson" },
    { name: "Manchester", slug: "?q=Manchester&format=inPerson" }
  ];

  return (
    <div className={`${cn()} ${styles.homePage()}`}>
      <HomePageMetadata />
      {AuthenticationState.helpers.isOnSales() && (
        <HeaderHomepageSales
          discount={AuthenticationState.helpers.getSales()}
          onExploreAllClasses={actions.onClickAllClasses}
          onLoadTags={actions.onLoadTags}
          onSearch={actions.onSearchTags}
          tags={tags}
        />
      )}
      {!AuthenticationState.helpers.isOnSales() && (
        <SearchHeader
          backgroundImageUrl="https://res.cloudinary.com/obby/image/upload/v1620903762/vi4zy46cvc4wuvhikvdw_1_2_nswmn3.png"
          onLoadGeoTags={actions.onLoadGeoTags}
          onLoadTags={actions.onLoadTags}
          onSearchGeoTags={actions.onSearchGeoTags}
          onSearchTags={actions.onSearchTags}
          tags={tags}
          geotags={geotags}
        />
      )}
      <div className={`${cn("review-banner")} d-none d-lg-inline`}>
        <ReviewsBanner background="ebonyClay" foreground="robbinsEggBlue" />
      </div>
      <ContainerFluid>
        {homepageCarousel?.enabled && (
          <div className={`d-sm-none ${cn("section")} ${styles.section()}`}>
            <ResponsiveClassSection fluid>
              <div className="row">
                <div className="col landing-page-content__no-gutters">
                  <Carousel
                    showIndicator={true}
                    imagesURL={homepageCarousel.value.map(item => ({
                      url: item.url,
                      imgURL: item.imageUrl,
                      imgCaption: item.caption
                    }))}
                    sizes={[
                      {
                        minWidth: 1200,
                        width: 600,
                        height: 400
                      },
                      {
                        width: 343,
                        height: 279
                      }
                    ]}
                  />
                </div>
              </div>
            </ResponsiveClassSection>
          </div>
        )}
        <div className={`${cn("section")} d-lg-none ${styles.section()}`}>
          <ReviewsBanner
            title="TRUSTED BY THE WORLD'S BIGGEST BRANDS"
            background="ebonyClay"
            foreground="robbinsEggBlue"
          />
        </div>
        {featuredClasses.length > 0 && (
          <div className={`${cn("section")} ${styles.section()}`}>
            <ResponsiveClassCards
              sortCard={false}
              title="Obby's Top Picks"
              cards={featuredClasses.map(course => ({
                title: course.title,
                thumbnail: course.thumbnail
                  ? ImageUrl__ResizedImageUrl(
                      { width: 450, height: 300, crop: "fill" },
                      course.thumbnail
                    )
                  : undefined,
                rating: Math.floor((course.summary.rating / 2) * 10) / 10,
                isOnline: course.isOnline,
                requiresSendingPhysicalMaterial:
                  course.requiresSendingPhysicalMaterial,
                numberOfReviews: course.summary.numberOfReviews,
                location: course.summary.location,
                price: Course__Price(course),
                samePriceAlways: !Course__IsPriceFrom(course),
                url: course.url,
                isFavourite: course.isFavourite,
                discount: course.discount,
                onAddToWishList: () => actions.onAddClassToWishList(course._id),
                onRemoveFromWishList: () =>
                  actions.onRemoveClassFromWishList(course._id)
              }))}
              numberOfColumns={4}
            />
          </div>
        )}
        <div className={`${cn("section")} ${styles.section()}`}>
          <ResponsiveClassCards
            title="What’s coming up"
            cards={upcomingClasses.map(course => ({
              title: course.title,
              thumbnail: course.thumbnail
                ? ImageUrl__ResizedImageUrl(
                    { width: 450, height: 300, crop: "fill" },
                    course.thumbnail
                  )
                : undefined,
              rating: Math.floor((course.summary.rating / 2) * 10) / 10,
              isOnline: course.isOnline,
              requiresSendingPhysicalMaterial:
                course.requiresSendingPhysicalMaterial,
              numberOfReviews: course.summary.numberOfReviews,
              location: course.summary.location,
              price: Course__Price(course),
              samePriceAlways: !Course__IsPriceFrom(course),
              url: course.url,
              isFavourite: course.isFavourite,
              discount: course.discount,
              onAddToWishList: () => actions.onAddClassToWishList(course._id),
              onRemoveFromWishList: () =>
                actions.onRemoveClassFromWishList(course._id)
            }))}
            numberOfColumns={4}
          />
        </div>
        <div className={`${cn("section")} ${styles.section()}`}>
          <WideBannerWithImage
            discount={AuthenticationState.helpers.getSales()}
            category="crafts"
            isGiftCard={true}
            label="Gifting on Obby"
            title="Choose From over 2500 classes on obby"
            description="Gift someone an obby gift card and let them pick from over 2500 classes to learn something new. What will you help someone learn?"
            image={ImageUrl__ImageResizedUrl(
              { width: 550 },
              "https://res.cloudinary.com/obby/images/yu0umrtxvaglizvmpugb/mothers-day-pottery-1553211934.jpg"
            )}
            cardPrice="£55"
            buttonText="Send a gift card"
            backgroundColor="technology"
            onClick={actions.onClickGiftCards}
          />
        </div>
        <div className={`${cn("section")} ${styles.section()}`}>
          <TrustedByBanner
            title="TRUSTED BY THE WORLD'S BIGGEST BRANDS"
            background="ebonyClay"
            foreground="robbinsEggBlue"
          />
        </div>
        <div className={`${cn("section")} ${styles.section()}`}>
          <ReviewsSlider
            reviews={reviews.map(review => {
              const course = helpers.getCourse(review.targetId);
              return {
                review: review.text,
                rating: review.value,
                reviewer: review.name,
                ...(course
                  ? {
                      target: course.title,
                      targetUrl: Course__CourseUrl(course)
                    }
                  : {})
              };
            })}
          />
        </div>
        <div className={`${cn("section")} ${styles.section()}`}>
          <WideBanner
            title="GROW YOUR BUSINESS WITH OBBY"
            description="Earn and grow your income with Obby. A powerful, all-in-one teaching platform, timetabling, automated emails, marketing, discounts and much more..."
            image="https://res.cloudinary.com/obby/image/upload/v1682496427/Page%20Images/Obby_Printmaking-18_1.png"
            buttonText="Join Obby Today"
            onButtonClick={actions.onJoinObbyToday}
          />
        </div>
        <div className={`${cn("section")} ${styles.section()}`}>
          <ResponsiveProductsCards
            title="Buy a learning kit today, learn from home tomorrow"
            cards={products.map(product => ({
              title: product.name,
              thumbnail: product.cover,
              price: product.price,
              stock: product.stock,
              url: Product__ProductUrl(product),
              shortDescription: product.shortDescription,
              rating: product.summary.rating,
              numberOfReviews: product.summary.numberOfReviews,
              discount: product.discount
            }))}
            numberOfColumns={4}
          />
        </div>
        <div className={`${cn("section")} ${styles.section()}`}>
          <div
            className={`${cn("section-title", {
              big: true
            })} ${styles.title()}`}
          >
            Rethink Learning
          </div>
          <div
            className={`${cn("section-description", {
              big: true
            })} ${styles.description()}`}
          >
            Learn from local experts in-person, live online or at home
          </div>
          <div className="row mt-4 mobile">
            <div className="col-12 col-sm-4 mb-4 mb-sm-0 pb-4 pb-sm-0">
              <SquareCard
                mobile
                url="/learn/?q=london&format=inPerson"
                thumbnail="https://res.cloudinary.com/obby/image/upload/v1635951209/Page%20Images/DSC_1937_1.png"
                location="LONDON"
              />
            </div>
            <div className="col-12 col-sm-4 mb-4 mb-sm-0 pb-4 pb-sm-0">
              <SquareCard
                mobile
                url="/learn/?q=brighton&format=inPerson"
                thumbnail="https://res.cloudinary.com/obby/image/upload/v1635951212/Page%20Images/clp4p7ywhgddoxecjodc_1.png"
                location="BRIGHTON"
              />
            </div>
            <div className="col-12 col-sm-4 mb-4 mb-sm-0 pb-4 pb-sm-0">
              <SquareCard
                mobile
                url="/learn/?q=manchester&format=inPerson"
                thumbnail="https://res.cloudinary.com/obby/image/upload/v1635951216/Page%20Images/nrportsejohbf005n3ob_1.png"
                location="MANCHESTER"
              />
            </div>
          </div>
        </div>
        <div className={`${cn("section")} ${styles.section()}`}>
          <InfoBanner
            title="Why Obby is the best place to learn"
            sections={[
              {
                icon: Icons.ThreePeopleThin,
                subTitle:
                  "Choose between in-person, live online or at home learning",
                description:
                  "Learn anytime, anywhere, anyhow. There’s is something for everyone."
              },
              {
                icon: Icons.GradHatThin,
                subTitle: "Experienced teachers",
                description: "We work with the best teachers in the UK!"
              },
              {
                icon: Icons.BulbThin,
                subTitle: "quality guarantee",
                description:
                  "We’ll give you your money back if your class, kit or product doesn’t reach your expectations."
              }
            ]}
          />
        </div>
        <div className={`${cn("section")} ${styles.section()}`}>
          <MotivationBanner
            headerText={[
              {
                icon: Icons.Learn,
                postText: "in a way"
              },
              "that suits you"
            ]}
            headerDescription="At Obby we know everyone learns differently. Thats why we have provided multiple ways for you to learn in a way that suits you! "
            motivationPoints={[
              {
                header: "Learn what you want",
                description:
                  "Our classes and kits cover a range of different subjects. You’ll be surprised by how much you can learn."
              },
              {
                header: "Get hands on",
                description:
                  "With our in-person workshops, you’ll get hands on with your teacher and learn to create something to keep."
              },
              {
                header: "Private and group learning",
                description:
                  "Can’t see a date that works for you, or looking to arrange a bespoke event? Our teachers can create a class or kit that works for you."
              },
              {
                header: "Save on travel",
                description:
                  "With our Live Online classes, Pre-recorded Courses and Learning Kits, you can learn from the comfort of your own sofa."
              }
            ]}
          />
        </div>
      </ContainerFluid>
    </div>
  );
}
