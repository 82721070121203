import { css } from "@obby/components";

export const styles = {
  headerHomePageSales: css({
    position: "relative"
  }),

  callToAction: css({
    fontFamily: "$foundersGrotesk",
    fontWeight: "600",
    fontSize: "$mParagraph",
    lineHeight: "1",
    margin: "$30 0"
  }),

  discount: css({
    color: "#bfefdc",
    backgroundColor: "$robbinsEggBlue",
    padding: "0 5px",
    display: "inline-block"
  }),

  searchInput: css({
    color: "$ebonyClay",
    marginTop: "$15",
    width: "100%",
    alignSelf: "center",

    "@sm": {
      margin: "$18 auto"
    },

    "@md": {
      maxWidth: "600px",
      width: "100%"
    },

    "@lg": {
      marginTop: "$30"
    }
  }),

  discountBadgeWrapper: css({
    overflow: "hidden",
    width: "100%",
    position: "absolute",
    top: "-16px",
    right: "-6px",
    textAlign: "right",
    "@lg": {
      top: "auto",
      bottom: "-16px"
    }
  }),

  note: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$14",
    fontWeight: "bold",
    marginTop: "$45",
    opacity: "0.6"
  }),
  imageSection: css({
    position: "relative"
  })
};
